/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplyMarginRequest } from '../models/ApplyMarginRequest';
import type { BatchPaymentRequest } from '../models/BatchPaymentRequest';
import type { BillingTransportType } from '../models/BillingTransportType';
import type { CarrierInvoiceRequest } from '../models/CarrierInvoiceRequest';
import type { CarrierTrackingUpdateRequest } from '../models/CarrierTrackingUpdateRequest';
import type { ConsolidationType } from '../models/ConsolidationType';
import type { CreateInvoiceConsolidationRequest } from '../models/CreateInvoiceConsolidationRequest';
import type { Currency } from '../models/Currency';
import type { DeletePriceReviewRequest } from '../models/DeletePriceReviewRequest';
import type { EditInvoiceCsvBatchInvoiceDateRequest } from '../models/EditInvoiceCsvBatchInvoiceDateRequest';
import type { EditInvoiceDetailsRequest } from '../models/EditInvoiceDetailsRequest';
import type { EditOrderPendingPriceReviewRequest } from '../models/EditOrderPendingPriceReviewRequest';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { Invoice } from '../models/Invoice';
import type { InvoiceEmailSendingStatus } from '../models/InvoiceEmailSendingStatus';
import type { InvoiceOrderRequest } from '../models/InvoiceOrderRequest';
import type { InvoiceStatus } from '../models/InvoiceStatus';
import type { InvoiceType } from '../models/InvoiceType';
import type { PendingPriceReviewRequest } from '../models/PendingPriceReviewRequest';
import type { PendingPriceReviewsRequest } from '../models/PendingPriceReviewsRequest';
import type { ResendInvoiceConsolidationRequest } from '../models/ResendInvoiceConsolidationRequest';
import type { ResendInvoiceRequest } from '../models/ResendInvoiceRequest';
import type { ResendOrderInvoiceRequest } from '../models/ResendOrderInvoiceRequest';
import type { ReviewOrderPriceRequest } from '../models/ReviewOrderPriceRequest';
import type { SendInvoiceConsolidationRequest } from '../models/SendInvoiceConsolidationRequest';
import type { SendOrderInvoiceRequest } from '../models/SendOrderInvoiceRequest';
import type { SuccessResponse_ApplyMarginResponse_ } from '../models/SuccessResponse_ApplyMarginResponse_';
import type { SuccessResponse_BatchPaymentResponse_ } from '../models/SuccessResponse_BatchPaymentResponse_';
import type { SuccessResponse_CarrierInvoiceResponse_ } from '../models/SuccessResponse_CarrierInvoiceResponse_';
import type { SuccessResponse_CarrierInvoiceTransportTypesResponse_ } from '../models/SuccessResponse_CarrierInvoiceTransportTypesResponse_';
import type { SuccessResponse_CarrierTrackingUpdateResponse_ } from '../models/SuccessResponse_CarrierTrackingUpdateResponse_';
import type { SuccessResponse_CreateInvoiceConsolidationResponse_ } from '../models/SuccessResponse_CreateInvoiceConsolidationResponse_';
import type { SuccessResponse_DeletePriceReviewResponse_ } from '../models/SuccessResponse_DeletePriceReviewResponse_';
import type { SuccessResponse_EditInvoiceCsvBatchInvoiceDateResponse_ } from '../models/SuccessResponse_EditInvoiceCsvBatchInvoiceDateResponse_';
import type { SuccessResponse_EditOrderPendingPriceReviewResponse_ } from '../models/SuccessResponse_EditOrderPendingPriceReviewResponse_';
import type { SuccessResponse_GetAddressesResponse_ } from '../models/SuccessResponse_GetAddressesResponse_';
import type { SuccessResponse_GetApplyPaymentInvoicesResponse_ } from '../models/SuccessResponse_GetApplyPaymentInvoicesResponse_';
import type { SuccessResponse_GetConsolidationsResponse_ } from '../models/SuccessResponse_GetConsolidationsResponse_';
import type { SuccessResponse_GetDefaultBillingUserAddressByIdResponse_ } from '../models/SuccessResponse_GetDefaultBillingUserAddressByIdResponse_';
import type { SuccessResponse_GetDefaultBillingUserAddressResponse_ } from '../models/SuccessResponse_GetDefaultBillingUserAddressResponse_';
import type { SuccessResponse_GetInvoiceByIdResponse_ } from '../models/SuccessResponse_GetInvoiceByIdResponse_';
import type { SuccessResponse_GetInvoicesResponse_ } from '../models/SuccessResponse_GetInvoicesResponse_';
import type { SuccessResponse_GetOrderInvoiceCsvBatchByIdResponse_ } from '../models/SuccessResponse_GetOrderInvoiceCsvBatchByIdResponse_';
import type { SuccessResponse_GetOrderInvoiceCsvBatchResponse_ } from '../models/SuccessResponse_GetOrderInvoiceCsvBatchResponse_';
import type { SuccessResponse_GetOrderPriceReviewByIdResponse_ } from '../models/SuccessResponse_GetOrderPriceReviewByIdResponse_';
import type { SuccessResponse_GetOrderPriceReviewsResponse_ } from '../models/SuccessResponse_GetOrderPriceReviewsResponse_';
import type { SuccessResponse_GetOrderTrackingUpdateCsvBatchByIdResponse_ } from '../models/SuccessResponse_GetOrderTrackingUpdateCsvBatchByIdResponse_';
import type { SuccessResponse_GetOrderTrackingUpdateCsvBatchesResponse_ } from '../models/SuccessResponse_GetOrderTrackingUpdateCsvBatchesResponse_';
import type { SuccessResponse_GetStatementOfAccountResponse_ } from '../models/SuccessResponse_GetStatementOfAccountResponse_';
import type { SuccessResponse_InvoiceOrderResponse_ } from '../models/SuccessResponse_InvoiceOrderResponse_';
import type { SuccessResponse_PaymentAccountResponse_ } from '../models/SuccessResponse_PaymentAccountResponse_';
import type { SuccessResponse_PaymentMethodResponse_ } from '../models/SuccessResponse_PaymentMethodResponse_';
import type { SuccessResponse_PendingPriceReviewsResponse_ } from '../models/SuccessResponse_PendingPriceReviewsResponse_';
import type { SuccessResponse_RemoveDefaultBillingUserAdressResponse_ } from '../models/SuccessResponse_RemoveDefaultBillingUserAdressResponse_';
import type { SuccessResponse_ResendEdiInvoiceResponse_ } from '../models/SuccessResponse_ResendEdiInvoiceResponse_';
import type { SuccessResponse_ResendInvoiceConsolidationResponse_ } from '../models/SuccessResponse_ResendInvoiceConsolidationResponse_';
import type { SuccessResponse_ResendOrderInvoiceResponse_ } from '../models/SuccessResponse_ResendOrderInvoiceResponse_';
import type { SuccessResponse_ReviewOrderPriceResponse_ } from '../models/SuccessResponse_ReviewOrderPriceResponse_';
import type { SuccessResponse_SendInvoiceConsolidationResponse_ } from '../models/SuccessResponse_SendInvoiceConsolidationResponse_';
import type { SuccessResponse_SendOrderInvoiceResponse_ } from '../models/SuccessResponse_SendOrderInvoiceResponse_';
import type { SuccessResponse_void_ } from '../models/SuccessResponse_void_';
import type { SuccessResponse_VoidInvoiceResponse_ } from '../models/SuccessResponse_VoidInvoiceResponse_';
import type { UuidV4 } from '../models/UuidV4';
import { request as __request } from '../core/request';

export class BillingService {

    /**
     * Apply margin to an order cost charges
     * @param requestBody The data
     * @returns SuccessResponse_ApplyMarginResponse_ OK
     * @throws ApiError
     */
    public static async applyMargin(
requestBody: ApplyMarginRequest,
): Promise<SuccessResponse_ApplyMarginResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/apply-margin`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an invoice for an order
     * @param currency 
     * @param requestBody The data
     * @returns SuccessResponse_BatchPaymentResponse_ OK
     * @throws ApiError
     */
    public static async batchPayments(
currency: Currency,
requestBody: BatchPaymentRequest,
): Promise<SuccessResponse_BatchPaymentResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/apply-payment`,
            query: {
                'currency': currency,
            },
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Approve pending price reviews
     * @param requestBody The data
     * @returns SuccessResponse_PendingPriceReviewsResponse_ OK
     * @throws ApiError
     */
    public static async approveOrderPendingPriceReview(
requestBody: PendingPriceReviewsRequest,
): Promise<SuccessResponse_PendingPriceReviewsResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/approve-pending-price-reviews`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an invoice for an order
     * @param requestBody The data
     * @returns any Ok
     * @throws ApiError
     */
    public static async createInvoiceConsolidation(
requestBody: CreateInvoiceConsolidationRequest,
): Promise<(SuccessResponse_CreateInvoiceConsolidationResponse_ | ErrorResponse)> {
        const result = await __request({
            method: 'POST',
            path: `/create-invoice-consolidation`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Delete a price review.
     * @param requestBody The data
     * @returns SuccessResponse_DeletePriceReviewResponse_ OK
     * @throws ApiError
     */
    public static async deletePriceReview(
requestBody: DeletePriceReviewRequest,
): Promise<SuccessResponse_DeletePriceReviewResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/delete-price-review`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit the invoice details
     * @param requestBody The data
     * @returns SuccessResponse_void_ OK
     * @throws ApiError
     */
    public static async editInvoiceDetails(
requestBody: EditInvoiceDetailsRequest,
): Promise<SuccessResponse_void_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-invoice-details`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Remove an address from the organization
     * @param requestBody The data of the invoice csv batch to edit
     * @returns SuccessResponse_EditInvoiceCsvBatchInvoiceDateResponse_ OK
     * @throws ApiError
     */
    public static async editOrderInvoiceCsvBatch(
requestBody: EditInvoiceCsvBatchInvoiceDateRequest,
): Promise<SuccessResponse_EditInvoiceCsvBatchInvoiceDateResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-order-invoice-csv-batch`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Edit the pending review of the order
     * @param requestBody The data
     * @returns SuccessResponse_EditOrderPendingPriceReviewResponse_ OK
     * @throws ApiError
     */
    public static async editOrderPendingPriceReview(
requestBody: EditOrderPendingPriceReviewRequest,
): Promise<SuccessResponse_EditOrderPendingPriceReviewResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/edit-order-pending-price-review`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an invoice for an order
     * @param requestBody The data
     * @returns any Ok
     * @throws ApiError
     */
    public static async invoiceOrder(
requestBody: InvoiceOrderRequest,
): Promise<(SuccessResponse_InvoiceOrderResponse_ | ErrorResponse)> {
        const result = await __request({
            method: 'POST',
            path: `/invoice-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an invoice for an order
     * @param requestBody The data
     * @returns SuccessResponse_CarrierInvoiceResponse_ OK
     * @throws ApiError
     */
    public static async processCarrierInvoice(
requestBody: CarrierInvoiceRequest,
): Promise<SuccessResponse_CarrierInvoiceResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/process-carrier-invoice`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate a tracking update for an order
     * @param requestBody The data
     * @returns SuccessResponse_CarrierTrackingUpdateResponse_ OK
     * @throws ApiError
     */
    public static async processTrackingUpdate(
requestBody: CarrierTrackingUpdateRequest,
): Promise<SuccessResponse_CarrierTrackingUpdateResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/process-carrier-tracking-update`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Reject pending price review
     * @param requestBody The data
     * @returns SuccessResponse_PendingPriceReviewsResponse_ OK
     * @throws ApiError
     */
    public static async rejectOrderPendingPriceReview(
requestBody: PendingPriceReviewRequest,
): Promise<SuccessResponse_PendingPriceReviewsResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/reject-pending-price-review`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Remove an address from the organization
     * @param addressId The ID of the address to delete
     * @returns SuccessResponse_RemoveDefaultBillingUserAdressResponse_ OK
     * @throws ApiError
     */
    public static async removeDefaultBillingUserAddresses(
addressId: UuidV4,
): Promise<SuccessResponse_RemoveDefaultBillingUserAdressResponse_> {
        const result = await __request({
            method: 'DELETE',
            path: `/remove-default-billing-user-address/${addressId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an invoice for an order
     * @param requestBody The data
     * @returns any Ok
     * @throws ApiError
     */
    public static async resendEdiInvoice(
requestBody: ResendInvoiceRequest,
): Promise<(SuccessResponse_ResendEdiInvoiceResponse_ | ErrorResponse)> {
        const result = await __request({
            method: 'POST',
            path: `/resend-edi-invoice`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Resends invoice consolidation to the contact email.
     * @param requestBody The data
     * @returns SuccessResponse_ResendInvoiceConsolidationResponse_ OK
     * @throws ApiError
     */
    public static async resendInvoiceConsolidation(
requestBody: ResendInvoiceConsolidationRequest,
): Promise<SuccessResponse_ResendInvoiceConsolidationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/resend-invoice-consolidation`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Resends order invoice to the contact email.
     * @param requestBody The data
     * @returns SuccessResponse_ResendOrderInvoiceResponse_ OK
     * @throws ApiError
     */
    public static async resendOrderInvoice(
requestBody: ResendOrderInvoiceRequest,
): Promise<SuccessResponse_ResendOrderInvoiceResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/resend-invoice`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Review an order's price (for agents / 3PL users)
     * @param requestBody The data
     * @returns SuccessResponse_ReviewOrderPriceResponse_ OK
     * @throws ApiError
     */
    public static async reviewOrderPrice(
requestBody: ReviewOrderPriceRequest,
): Promise<SuccessResponse_ReviewOrderPriceResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/review-order-price`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Sends invoice consolidation to the contact email.
     * @param requestBody The data
     * @returns SuccessResponse_SendInvoiceConsolidationResponse_ OK
     * @throws ApiError
     */
    public static async sendInvoiceConsolidation(
requestBody: SendInvoiceConsolidationRequest,
): Promise<SuccessResponse_SendInvoiceConsolidationResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/send-invoice-consolidation`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Sends order invoice to the contact email.
     * @param requestBody The data
     * @returns SuccessResponse_SendOrderInvoiceResponse_ OK
     * @throws ApiError
     */
    public static async sendOrderInvoice(
requestBody: SendOrderInvoiceRequest,
): Promise<SuccessResponse_SendOrderInvoiceResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/send-invoice`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Void Invoice
     * @param requestBody The data
     * @returns SuccessResponse_VoidInvoiceResponse_ OK
     * @throws ApiError
     */
    public static async voidInvoice(
requestBody: Invoice,
): Promise<SuccessResponse_VoidInvoiceResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/void-invoice`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of invoices to apply payment
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param invoiceStatus The list of invoice status to filter by
     * @param clientOrganizationId The client organization id to filter by
     * @param pickUpDateFrom The start of the pick up date range to filter by
     * @param pickUpDateTo The end of the pick up date range to filter by
     * @param order Order direction of orderBy
     * @param orderBy Field to order by
     * @param dueDateFrom The start of the due date range to filter by
     * @param dueDateTo The end of the due date range to filter by
     * @param billToAddressId The billing address id to filter by
     * @param currency The currency to filter by
     * @param excludeCreditNotes 
     * @param excludeXeroInvoices 
     * @param types 
     * @returns SuccessResponse_GetApplyPaymentInvoicesResponse_ OK
     * @throws ApiError
     */
    public static async getApplyPaymentInvoices(
page?: number,
resultsPerPage?: number,
invoiceStatus?: Array<InvoiceStatus>,
clientOrganizationId?: UuidV4,
pickUpDateFrom?: string,
pickUpDateTo?: string,
order?: 'asc' | 'desc',
orderBy?: 'external_number' | 'order_number' | 'status' | 'invoice_due_date' | 'quote_price' | 'invoice_price' | 'invoice_date' | 'billing_po' | 'currency' | 'client_organization_name' | 'three_pl_organization_name',
dueDateFrom?: string,
dueDateTo?: string,
billToAddressId?: UuidV4,
currency?: Currency,
excludeCreditNotes?: boolean,
excludeXeroInvoices?: boolean,
types?: Array<InvoiceType>,
): Promise<SuccessResponse_GetApplyPaymentInvoicesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/apply-payment-invoices`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'invoiceStatus': invoiceStatus,
                'clientOrganizationId': clientOrganizationId,
                'pickUpDateFrom': pickUpDateFrom,
                'pickUpDateTo': pickUpDateTo,
                'order': order,
                'orderBy': orderBy,
                'dueDateFrom': dueDateFrom,
                'dueDateTo': dueDateTo,
                'billToAddressId': billToAddressId,
                'currency': currency,
                'excludeCreditNotes': excludeCreditNotes,
                'excludeXeroInvoices': excludeXeroInvoices,
                'types': types,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of billing addresses saved in your organization
     * @param organizationId The organization id to filter by
     * @returns SuccessResponse_GetAddressesResponse_ OK
     * @throws ApiError
     */
    public static async getBillingAddresses(
organizationId: UuidV4,
): Promise<SuccessResponse_GetAddressesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/billing-addresses`,
            query: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of carrier invoice transport types
     * @param carrierName 
     * @returns SuccessResponse_CarrierInvoiceTransportTypesResponse_ OK
     * @throws ApiError
     */
    public static async getCarrierInvoiceTransportTypes(
carrierName: string,
): Promise<SuccessResponse_CarrierInvoiceTransportTypesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/carrier-invoice-transport-types`,
            query: {
                'carrierName': carrierName,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of invoices for your organization
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param number 
     * @param orderNumber The order number to filter by
     * @param invoiceStatus The list of invoice statuses to filter by
     * @param billingPoNumber 
     * @param clientOrganizationId The client organization id to filter by
     * @param types The list of invoice types to filter by
     * @param minDifference The minimum price difference to filter by
     * @param maxDifference The maximum price differene to filter by
     * @param pickUpDateFrom The start of the pick up date range to filter by
     * @param pickUpDateTo The end of the pick up date range to filter by
     * @param withPod The field withPOD to filter by
     * @param orderId 
     * @param dueDateFrom The start of the due date range to filter by
     * @param dueDateTo The end of the due date range to filter by
     * @param transportType The Field transport type to filter by
     * @param billToAddressId The billing address id to filter by
     * @param currency The currency to filter by
     * @param excludeCreditNotes 
     * @param excludeXeroInvoices 
     * @param pastDueInvoices 
     * @returns SuccessResponse_GetConsolidationsResponse_ OK
     * @throws ApiError
     */
    public static async getConsolidations(
page?: number,
resultsPerPage?: number,
number?: string,
orderNumber?: string,
invoiceStatus?: Array<InvoiceStatus>,
billingPoNumber?: string,
clientOrganizationId?: UuidV4,
types?: Array<InvoiceType>,
minDifference?: number,
maxDifference?: number,
pickUpDateFrom?: string,
pickUpDateTo?: string,
withPod?: boolean,
orderId?: UuidV4,
dueDateFrom?: string,
dueDateTo?: string,
transportType?: BillingTransportType,
billToAddressId?: UuidV4,
currency?: Currency,
excludeCreditNotes?: boolean,
excludeXeroInvoices?: boolean,
pastDueInvoices?: boolean,
): Promise<SuccessResponse_GetConsolidationsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/consolidations`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'number': number,
                'orderNumber': orderNumber,
                'invoiceStatus': invoiceStatus,
                'billingPoNumber': billingPoNumber,
                'clientOrganizationId': clientOrganizationId,
                'types': types,
                'minDifference': minDifference,
                'maxDifference': maxDifference,
                'pickUpDateFrom': pickUpDateFrom,
                'pickUpDateTo': pickUpDateTo,
                'withPOD': withPod,
                'orderId': orderId,
                'dueDateFrom': dueDateFrom,
                'dueDateTo': dueDateTo,
                'transportType': transportType,
                'billToAddressId': billToAddressId,
                'currency': currency,
                'excludeCreditNotes': excludeCreditNotes,
                'excludeXeroInvoices': excludeXeroInvoices,
                'pastDueInvoices': pastDueInvoices,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an address by its ID
     * @param id The ID of the Default Billing User address to get
     * @param organizationId 
     * @returns SuccessResponse_GetDefaultBillingUserAddressByIdResponse_ OK
     * @throws ApiError
     */
    public static async getDefaultBillingUserAddressById(
id: string,
organizationId?: UuidV4,
): Promise<SuccessResponse_GetDefaultBillingUserAddressByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/default-billing-user-by-id/${id}`,
            query: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get default billing user address saved in your organization and user
     * @param adressIds The address IDs
     * @param organizationId The organization ID
     * @returns SuccessResponse_GetDefaultBillingUserAddressResponse_ OK
     * @throws ApiError
     */
    public static async getDefaultBillingUserAddress(
adressIds: Array<UuidV4>,
organizationId: UuidV4,
): Promise<SuccessResponse_GetDefaultBillingUserAddressResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/default-billing-user-address`,
            query: {
                'adressIds': adressIds,
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get invoice by  ID
     * @param id The invoice ID
     * @returns SuccessResponse_GetInvoiceByIdResponse_ OK
     * @throws ApiError
     */
    public static async getInvoiceById(
id: UuidV4,
): Promise<SuccessResponse_GetInvoiceByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/invoice-by-id/${id}`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of invoices for your organization
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param consolidationId The consolidation id to filter by
     * @param number The invoice number to filter by
     * @param orderNumber The order number to filter by
     * @param invoiceStatus The list of invoice status to filter by
     * @param billingPoNumber The list of billing PO numbers to filter by
     * @param clientOrganizationId The client organization id to filter by
     * @param types The list of invoice types to filter by
     * @param minDifference The minimum price difference to filter by
     * @param maxDifference The maximum price differene to filter by
     * @param pickUpDateFrom The start of the pick up date range to filter by
     * @param pickUpDateTo The end of the pick up date range to filter by
     * @param withPod The field withPOD to filter by
     * @param orderId The field order Id to filter by
     * @param order Order direction of orderBy
     * @param orderBy Field to order by
     * @param dueDateFrom The start of the due date range to filter by
     * @param dueDateTo The end of the due date range to filter by
     * @param transportType The Field transport type to filter by
     * @param billToAddressId The billing address id to filter by
     * @param currency The currency to filter by
     * @param excludeCreditNotes 
     * @param excludeXeroInvoices 
     * @param pastDueInvoices 
     * @returns SuccessResponse_GetInvoicesResponse_ OK
     * @throws ApiError
     */
    public static async getInvoices(
page?: number,
resultsPerPage?: number,
consolidationId?: UuidV4,
number?: string,
orderNumber?: string,
invoiceStatus?: Array<InvoiceStatus>,
billingPoNumber?: string,
clientOrganizationId?: UuidV4,
types?: Array<InvoiceType>,
minDifference?: number,
maxDifference?: number,
pickUpDateFrom?: string,
pickUpDateTo?: string,
withPod?: boolean,
orderId?: UuidV4,
order?: 'asc' | 'desc',
orderBy?: 'external_number' | 'order_number' | 'status' | 'invoice_due_date' | 'quote_price' | 'invoice_price' | 'invoice_date' | 'billing_po' | 'currency' | 'client_organization_name' | 'three_pl_organization_name',
dueDateFrom?: string,
dueDateTo?: string,
transportType?: BillingTransportType,
billToAddressId?: UuidV4,
currency?: Currency,
excludeCreditNotes?: boolean,
excludeXeroInvoices?: boolean,
pastDueInvoices?: boolean,
): Promise<SuccessResponse_GetInvoicesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/invoices`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'consolidationId': consolidationId,
                'number': number,
                'orderNumber': orderNumber,
                'invoiceStatus': invoiceStatus,
                'billingPoNumber': billingPoNumber,
                'clientOrganizationId': clientOrganizationId,
                'types': types,
                'minDifference': minDifference,
                'maxDifference': maxDifference,
                'pickUpDateFrom': pickUpDateFrom,
                'pickUpDateTo': pickUpDateTo,
                'withPOD': withPod,
                'orderId': orderId,
                'order': order,
                'orderBy': orderBy,
                'dueDateFrom': dueDateFrom,
                'dueDateTo': dueDateTo,
                'transportType': transportType,
                'billToAddressId': billToAddressId,
                'currency': currency,
                'excludeCreditNotes': excludeCreditNotes,
                'excludeXeroInvoices': excludeXeroInvoices,
                'pastDueInvoices': pastDueInvoices,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of invoices for your new consolidation
     * @param clientOrganizationId The client organization id to filter by
     * @param transportType The transport type to filter by
     * @param billToAddressId The billing address id to filter by
     * @param consolidationType The consolidation type to filter by
     * @param currency The currency to filter by
     * @param invoiceDueDateFrom The start of the invoice due date range to filter by
     * @param invoiceDueDateTo The end of the invoice due date range to filter by
     * @param pickUpDateFrom The start of the pick up date range to filter by
     * @param pickUpDateTo The end of the pick up date range to filter by
     * @param order Order direction of orderBy
     * @param invoiceEmailSentStatus 
     * @returns SuccessResponse_GetInvoicesResponse_ OK
     * @throws ApiError
     */
    public static async getNewConsolidationInvoices(
clientOrganizationId: UuidV4,
transportType: BillingTransportType,
billToAddressId: UuidV4,
consolidationType: ConsolidationType,
currency: Currency,
invoiceDueDateFrom?: string,
invoiceDueDateTo?: string,
pickUpDateFrom?: string,
pickUpDateTo?: string,
order?: 'asc' | 'desc',
invoiceEmailSentStatus?: Array<InvoiceEmailSendingStatus>,
): Promise<SuccessResponse_GetInvoicesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/new-consolidation-invoices`,
            query: {
                'clientOrganizationId': clientOrganizationId,
                'transportType': transportType,
                'billToAddressId': billToAddressId,
                'consolidationType': consolidationType,
                'currency': currency,
                'invoiceDueDateFrom': invoiceDueDateFrom,
                'invoiceDueDateTo': invoiceDueDateTo,
                'pickUpDateFrom': pickUpDateFrom,
                'pickUpDateTo': pickUpDateTo,
                'order': order,
                'invoiceEmailSentStatus': invoiceEmailSentStatus,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an order invoice csv batch by its ID
     * @param orderInvoiceCsvBatchId The order invoice csv batch ID
     * @returns SuccessResponse_GetOrderInvoiceCsvBatchByIdResponse_ OK
     * @throws ApiError
     */
    public static async getOrderInvoiceCsvBatchById(
orderInvoiceCsvBatchId: string,
): Promise<SuccessResponse_GetOrderInvoiceCsvBatchByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-invoice-csv-batch-by-id/${orderInvoiceCsvBatchId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get order invoice csv batches
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param order Order direction of orderBy
     * @param orderBy Field to order by
     * @returns SuccessResponse_GetOrderInvoiceCsvBatchResponse_ OK
     * @throws ApiError
     */
    public static async getOrderInvoiceCsvBatches(
page?: number,
resultsPerPage?: number,
order?: 'asc' | 'desc',
orderBy?: 'number' | 'file_name' | 'carrier_name' | 'created_at' | 'created_by_user_name' | 'status' | 'transport_type',
): Promise<SuccessResponse_GetOrderInvoiceCsvBatchResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-invoice-csv-batches`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get an order price review by its ID
     * @param priceReviewId The price review ID
     * @returns SuccessResponse_GetOrderPriceReviewByIdResponse_ OK
     * @throws ApiError
     */
    public static async getOrderPriceReviewById(
priceReviewId: UuidV4,
): Promise<SuccessResponse_GetOrderPriceReviewByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-price-review-by-id/${priceReviewId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get the price reviews of an order
     * @param orderId The order ID that the price reviews belongs to
     * @returns SuccessResponse_GetOrderPriceReviewsResponse_ OK
     * @throws ApiError
     */
    public static async getOrderPriceReviews(
orderId: UuidV4,
): Promise<SuccessResponse_GetOrderPriceReviewsResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-price-reviews`,
            query: {
                'orderId': orderId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a tracking update csv batch by its ID
     * @param orderTrackingUpdateCsvBatchId The tracking update csv batch ID
     * @returns SuccessResponse_GetOrderTrackingUpdateCsvBatchByIdResponse_ OK
     * @throws ApiError
     */
    public static async getOrderTrackingUpdateCsvBatchById(
orderTrackingUpdateCsvBatchId: string,
): Promise<SuccessResponse_GetOrderTrackingUpdateCsvBatchByIdResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-tracking-update-csv-batch-by-id/${orderTrackingUpdateCsvBatchId}`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get order tracking update csv batches
     * @param page The page number
     * @param resultsPerPage The number of results per page
     * @param order Order direction of orderBy
     * @param orderBy Field to order by
     * @returns SuccessResponse_GetOrderTrackingUpdateCsvBatchesResponse_ OK
     * @throws ApiError
     */
    public static async getOrderTrackingUpdateCsvBatches(
page?: number,
resultsPerPage?: number,
order?: 'asc' | 'desc',
orderBy?: 'number' | 'file_name' | 'carrier_name' | 'created_at' | 'created_by_user_name',
): Promise<SuccessResponse_GetOrderTrackingUpdateCsvBatchesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/order-tracking-update-csv-batches`,
            query: {
                'page': page,
                'resultsPerPage': resultsPerPage,
                'order': order,
                'orderBy': orderBy,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get Payment Accounts
     * @param currency 
     * @param enablePaymentsToAccount 
     * @returns SuccessResponse_PaymentAccountResponse_ OK
     * @throws ApiError
     */
    public static async getAccounts(
currency: Currency,
enablePaymentsToAccount: boolean,
): Promise<SuccessResponse_PaymentAccountResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/payment-accounts`,
            query: {
                'currency': currency,
                'enablePaymentsToAccount': enablePaymentsToAccount,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Generate and send an invoice for an order
     * @returns SuccessResponse_PaymentMethodResponse_ OK
     * @throws ApiError
     */
    public static async getPaymentMethods(): Promise<SuccessResponse_PaymentMethodResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/payment-methods`,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of shipping addresses saved in your organization
     * @param organizationId The organization id to filter by
     * @returns SuccessResponse_GetAddressesResponse_ OK
     * @throws ApiError
     */
    public static async getShippingAddresses(
organizationId: UuidV4,
): Promise<SuccessResponse_GetAddressesResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/shipping-addresses`,
            query: {
                'organizationId': organizationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get statement of account
     * @returns SuccessResponse_GetStatementOfAccountResponse_ OK
     * @throws ApiError
     */
    public static async getStatementOfAccount(): Promise<SuccessResponse_GetStatementOfAccountResponse_> {
        const result = await __request({
            method: 'GET',
            path: `/statement-of-account`,
            errors: {
                401: `Unauthorized`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}
import { handleUnauthorizedException } from './index'
import { logger } from '../../logger'
import {
    ApiError,
    ErrorResponse,
    PickupInfoService,
    ServiceLevelService,
    UuidV4,
    PickupIdentifierSource,
    PickupIdentifierType,
} from '@lazr/openapi-client'
import { Pickup, PickupProps, SchedulePickupAttributes, ServiceLevel } from '../../model'

export interface PickupsPaging {
    page?: number
    resultPerPage?: number
}
export interface PickupIdentifier {
    source: PickupIdentifierSource
    type: PickupIdentifierType
    value: string
    primaryForType: boolean | null
}


export const PickupApiService = Object.freeze({
    getById: async (id: UuidV4): Promise<Pickup> => {
        let getPickupByIdResponse
        try {
            getPickupByIdResponse = await PickupInfoService.getPickupById(id)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getById', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to get pickup by id')
            }
            throw new Error('Unable to get pickup by id')
        }

        if (!getPickupByIdResponse.data) {
            logger.debug('getById', JSON.stringify(getPickupByIdResponse, null, 4))
            throw new Error('Unable to get pickup by id')
        }

        return new Pickup(getPickupByIdResponse.data.pickup)
    },
    list: async (paging: PickupsPaging) => {
        let getPickupsResponse
        try {
            getPickupsResponse = await PickupInfoService.getPickups(
                paging.page,
                paging.resultPerPage,
            )
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('pickups', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve pickup list')
            }
            throw new Error('Unable to retrieve pickup list')
        }

        if (!getPickupsResponse.data) {
            logger.debug('list', JSON.stringify(getPickupsResponse, null, 4))
            throw new Error('Unable to retrieve pickup list')
        }

        return {
            pickups: getPickupsResponse.data.pickups.map((pickup: PickupProps) => new Pickup(pickup)),
            total: getPickupsResponse.data.paging.items,
        }
    },

    schedulePickup: async(schedulePickupAttributes: SchedulePickupAttributes): Promise<{ newPickupDate?: string | null | undefined
        pickupIdentifiers: PickupIdentifier[] } | undefined> => {
        let schedulePickupResponse
        try {
            schedulePickupResponse =  await PickupInfoService.schedulePickup(schedulePickupAttributes)
            if (!schedulePickupResponse) {
                return
            }


        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('schedulePickup', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to schedule pickup')
            }
            throw new Error('Unable to schedule pickup')
        }
        if (!schedulePickupResponse.data) {
            logger.debug('schedulePickup', JSON.stringify(schedulePickupResponse, null, 4))
            throw new Error('Unable to schedule pickup')
        }
        return schedulePickupResponse.data.result
    },

    getServiceLevels: async(carrierId: UuidV4): Promise<ServiceLevel[]> => {
        let getServiceLevelsResponse
        try {
            getServiceLevelsResponse = await ServiceLevelService.getServiceLevelsByCarrierId(carrierId)
        } catch (error: any) {
            handleUnauthorizedException(error)
            logger.debug('getServiceLevels', JSON.stringify(error, null, 4))
            if (error instanceof ApiError) {
                const errorResponse = error.body as ErrorResponse
                throw new Error(errorResponse.error?.message || 'Unable to retrieve Service Levels')
            }
            throw new Error('Unable to retrieve Service Levels')
        }

        if (!getServiceLevelsResponse.data) {
            logger.debug('getServiceLevels', JSON.stringify(getServiceLevelsResponse, null, 4))
            throw new Error('Unable to retrieve Service Levels')
        }

        return getServiceLevelsResponse.data
},
})

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Obtain the return type of a function type
 */
export type ReturnType_typeofEdiTransactionSet210Schema_parse_ = {
    summary?: {
transactionSetTrailerSE?: {
numberOfIncludedSegments01?: number,
transactionSetControlNumber02?: string,
},
totalWeightAndChargesL3?: {
weightUnitCode12?: ReturnType_typeofEdiTransactionSet210Schema_parse_.weightUnitCode12,
ladingQuantity11?: number,
volumeUnitQualifier10?: string,
charge05?: number,
weightQualifier02?: ReturnType_typeofEdiTransactionSet210Schema_parse_.weightQualifier02,
weight01?: number,
volume09?: number,
},
};
    detail?: {
assignedNumberLXLoop?: Array<{
tariffReferenceL7?: Array<{
freightClassCode07?: '50' | '55' | '60' | '65' | '70' | '85' | '100' | '110' | '125' | '150' | '175' | '200' | '250' | '300' | '400' | '500' | '77.5' | '92.5',
ladingLineItemNumber01?: number,
}>,
rateAndChargesL1?: Array<{
tariffApplicationCode13?: string,
specialChargeOrAllowanceCode08?: '145' | '400' | '405' | '410' | '415' | '495' | '500' | '515' | '010' | 'CBX' | 'CRF' | 'DTL' | 'DTU' | 'EMT' | 'EXM' | 'GST' | 'HRS' | 'HST' | 'LAB' | 'LFT' | 'NDS' | 'PJA' | 'QST' | 'RCL' | 'REL' | 'STW' | 'TAF' | 'TAX' | 'VFN' | 'WTG',
rateValueQualifier03?: string,
rateClassCode09?: string,
freightRate02?: number,
charge04?: number,
ladingLineItemNumber01?: number,
declaredValue14?: number,
}>,
proofOfDeliveryPOD?: {
time02?: string,
name03?: string,
date01?: string,
},
measurementL4?: Array<{
width02?: number,
measurementUnitQualifier04?: 'N' | 'C',
length01?: number,
height03?: number,
}>,
lineItemQuantityAndWeightL0?: Array<{
weightUnitCode11?: 'K' | 'L',
weightQualifier05?: 'N' | 'G' | 'A1' | 'B',
weight04?: number,
packagingFormCode09?: string,
ladingQuantity08?: number,
billedRatedAsQuantity02?: number,
billedRatedAsQualifier03?: 'NR' | 'CC' | 'MT' | 'ND' | 'TD' | 'FR' | 'CM' | 'FT' | 'BA' | 'BX' | 'CF' | 'DK' | 'DM' | 'DR' | 'GC' | 'GL' | 'KG' | 'LB' | 'LC' | 'LH' | 'LR' | 'MR' | 'NG' | 'NL' | 'NP' | 'NU' | 'NV' | 'PK' | 'SP' | 'SY' | 'TH' | 'TN' | 'VM',
ladingLineItemNumber01?: number,
}>,
assignedNumberLX?: {
assignedNumber01?: number,
},
referenceIdentificationN9?: Array<{
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: 'BM' | 'PO',
}>,
descriptionMarksAndNumbersL5?: Array<{
ladingLineItemNumber01?: number,
ladingDescription02?: string,
}>,
}>,
stopOffDetailsS5Loop?: Array<{
referenceIdentificationN9?: Array<{
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: 'BM' | 'PO',
}>,
stopOffDetailsS5?: {
weightUnitCode04?: 'K' | 'L',
weight03?: number,
stopReasonCode02?: 'PU' | 'LD' | 'PL' | 'UL',
stopSequenceNumber01?: number,
},
nameN1Loop?: Array<{
referenceIdentificationN9?: Array<{
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: 'BM' | 'PO',
}>,
geographicLocationN4?: {
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
nameN1?: {
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: 'CN',
},
}>,
dateTimeG62?: Array<{
date02?: string,
dateQualifier01?: '11',
}>,
}>,
};
    heading?: {
routeInformationMotorR3?: {
transportationMethodTypeCode04?: string,
standardPointLocationCode05?: string,
routingSequenceCode02?: string,
cityName03?: string,
standardCarrierAlphaCode01?: string,
},
currencyC3?: {
exchangeRate02?: number,
currencyCode04?: string,
currencyCode03?: string,
currencyCode01?: string,
},
beginningSegmentForCarriersInvoiceB3?: {
standardCarrierAlphaCode11?: string,
shipmentMethodOfPayment04?: ReturnType_typeofEdiTransactionSet210Schema_parse_.shipmentMethodOfPayment04,
shipmentIdentificationNumber03?: string,
netAmountDue07?: number,
invoiceNumber02?: string,
deliveryDate09?: string,
dateTimeQualifier10?: ReturnType_typeofEdiTransactionSet210Schema_parse_.dateTimeQualifier10,
date06?: string,
correctionIndicator08?: ReturnType_typeofEdiTransactionSet210Schema_parse_.correctionIndicator08,
},
bankIdC2?: {
identificationCodeQualifier02?: ReturnType_typeofEdiTransactionSet210Schema_parse_.identificationCodeQualifier02,
identificationCode03?: string,
bankClientCode01?: ReturnType_typeofEdiTransactionSet210Schema_parse_.bankClientCode01,
},
referenceIdentificationN9?: Array<{
freeFormDescription03?: string,
referenceIdentification02?: string,
referenceIdentificationQualifier01?: 'BM' | 'PO' | 'CR',
}>,
equipmentDetailsN7Loop?: Array<{
equipmentDetailsN7?: {
weightQualifier04?: string,
tareWeight05?: number,
weight03?: number,
equipmentLength15?: number,
equipmentDescriptionCode11?: string,
equipmentNumber02?: string,
equipmentInitial01?: string,
},
}>,
nameN1Loop?: Array<{
geographicLocationN4?: {
countryCode04?: string,
postalCode03?: string,
stateOrProvinceCode02?: string,
cityName01?: string,
},
addressInformationN3?: Array<{
addressInformation02?: string,
addressInformation01?: string,
}>,
nameN1?: {
identificationCode04?: string,
identificationCodeQualifier03?: string,
name02?: string,
entityIdentifierCode01?: 'CN' | 'BT' | 'SH',
},
}>,
dateTimeG62?: Array<{
date02?: string,
dateQualifier01?: '11',
}>,
transactionSetHeaderST?: {
transactionSetControlNumber02?: string,
transactionSetIdentifierCode01?: ReturnType_typeofEdiTransactionSet210Schema_parse_.transactionSetIdentifierCode01,
},
};
}

export namespace ReturnType_typeofEdiTransactionSet210Schema_parse_ {

    export enum weightUnitCode12 {
        K = 'K',
        L = 'L',
    }

    export enum weightQualifier02 {
        N = 'N',
        G = 'G',
        B = 'B',
    }

    export enum shipmentMethodOfPayment04 {
        CC = 'CC',
        PP = 'PP',
        TP = 'TP',
    }

    export enum dateTimeQualifier10 {
        _035 = '035',
    }

    export enum correctionIndicator08 {
        BD = 'BD',
    }

    export enum identificationCodeQualifier02 {
        ZZ = 'ZZ',
    }

    export enum bankClientCode01 {
        E = 'E',
        R = 'R',
    }

    export enum transactionSetIdentifierCode01 {
        _210 = '210',
    }


}
import React, { useEffect, useState } from 'react'
import async from '../components/Async'
import reduxSetSnackbar from '../redux/actions/SnackbarActions'

import {
    CookieSnackbarButton,
    Drawer,
    drawerWidth,
    GenericAlert,
    LinkButton,
    Strong,
    StyledAlert,
    StyledLink,
    Typography,
    VersionSnackbarButton,
    VersionSnackbarTypography,
} from './Dashboard.styled'

import { GlobalStyle } from './components/GlobalStyle.styled'
import { Root } from './components/Root.styled'
import { AppContent } from './components/AppContent.styled'
import { MainContent } from './components/MainContent.styled'
import Sidebar from '../pages/components/Sidebar'
import Header from '../components/Header'
import * as ApiService from '../../service/ApiService'

import { CssBaseline, Grid, Hidden, Theme, useMediaQuery, useTheme } from '@material-ui/core'

import Snackbar from '@material-ui/core/Snackbar'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { Store } from '../definitions/Store'
import { AppTheme } from '../definitions/Theme'
import { LayoutProps } from '../routes'
import { getUser as reduxGetUser } from '../redux/selectors/UserSelectors'
import { getVersionSnackbarOpen as reduxGetVersionSnackbarOpen } from '../redux/selectors/VersionSelectors'
import { getUserOrganization as reduxGetUserOrganization } from '../redux/selectors/UserOrganizationSelectors'
import { getDefaultAddressList as reduxGetDefaultAddressList } from '../redux/selectors/AddressListSelectors'
import { OrganizationType } from '@lazr/openapi-client'
import { MessageType } from '../lib'
import { env, Environment } from '../../config'
import { UserRole } from '@lazr/enums'
import useSizeScreen from '@/app/ui-new/pages/hooks/useSizeScreen'
import { OrganizationHeader } from '@/app/ui-new/components/OrganizationHeader/OrganizationHeader'
import NotistackAlerts from '@/app/ui-new/layouts/NotistackAlerts'
import { useI18n } from '../components/hooks/I18n'

import i18n from '@/app/ui-new/layouts/components/NotistackAlert/Message/index.i18n'
import {
    StyledAlertBanner,
    StyledAlertBannerContent,
    StyledButton,
    StyledTypography,
} from '@/app/ui-new/layouts/components/NotistackAlert/Message/index.styled'

const Footer = async(() => import('../pages/components/Footer'), false)

const Alert = (props: any): JSX.Element => <StyledAlert elevation={6} variant='filled' {...props} />

const Dashboard: React.FunctionComponent<Props> = ({ contentFooter: ContentFooter, children }) => {
    const { t } = useI18n(i18n)

    const theme = useTheme<AppTheme & Theme>()
    const user = useSelector(reduxGetUser)
    const userOrganization = useSelector(reduxGetUserOrganization)
    const defaultAddressList = useSelector(reduxGetDefaultAddressList)
    const versionSnackbarOpen = useSelector(reduxGetVersionSnackbarOpen)

    const [showProductionWarningAlert, setShowProductionAlert] = useState<boolean>(false)

    const isProduction = env === Environment.PRODUCTION
    const isUserProfileComplete = user && user.firstName && user.lastName
    const hasDefaultBillingAddress = defaultAddressList && defaultAddressList.billingAddressList.length > 0

    const { snackbar } = useSelector((reduxStore: Store) => ({
        snackbar: reduxStore.snackbar,
    }))
    const dispatch = useDispatch()

    const [mobileOpen, setMobileOpen] = useState<boolean>(false)
    const monitor = useSizeScreen()

    const [snackbarBestExperienceOpen, setSnackbarBestExperienceOpen] = useState<boolean>(
        Cookies.get('cookie-experienceSizeScreen-agreed') !== 'true',
    )
    const [snackbarCookieLawOpen, setSnackbarCookieLawOpen] = useState<boolean>(Cookies.get('cookie-law-agreed') !== 'true')

    const handleDrawerToggle = (): void => {
        setMobileOpen(!mobileOpen)
    }

    const handleSnackbarClose = (): void => {
        dispatch(
            reduxSetSnackbar({
                ...snackbar,
                open: false,
            }),
        )
    }

    const handleSnackbarCookieBestExperienceClose = (reason: string): void => {
        if (reason === 'clickaway') {
            return
        }
        const aYearFromNow = new Date()
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
        Cookies.set('cookie-experienceSizeScreen-agreed', 'true', { expires: aYearFromNow })
        setSnackbarBestExperienceOpen(false)
    }

    const handleSnackbarCookieLawClose = (reason: string): void => {
        if (reason === 'clickaway') {
            return
        }
        const aYearFromNow = new Date()
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
        Cookies.set('cookie-law-agreed', 'true', { expires: aYearFromNow })
        setSnackbarCookieLawOpen(false)
    }

    const isOrganizationAdmin =
        (user?.permissions.organizationRole.role === UserRole.ADMIN || user?.getImpersonatingInfo().isAdmin) ?? false

    useEffect(() => {
        if (userOrganization?.type === OrganizationType.SYSTEM) {
            setShowProductionAlert(true)

            return
        }
        const impersonatedRole = ApiService.localStorageHelper.getImpersonatedRoleInfo()
        setShowProductionAlert(!!impersonatedRole.organizationId)
    }, [userOrganization])

    //MESSAGE ONCE A DAY:
    const [messageSnakbarOpen, setMessageSnakbarOpen] = useState(false)

    const setCookie = (name: string, value: string, days: number) => {
        const expires = new Date()
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000) // Set expiration in days
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=/'
    }

    const cookieName = 'messageStrike'
    const currentDate = new Date().toISOString().split('T')[0]

    // Function to get a cookie value by name
    const getCookie = (name: string) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
        return match ? match[2] : null
    }

    // Check if the message was already shown today

    useEffect(() => {
        const lastShownDate = getCookie(cookieName)
        console.log('lastShownDate', lastShownDate, 'currentDate', currentDate)
        if (lastShownDate !== currentDate) {
            // Show the message
            setMessageSnakbarOpen(true)
        }
    }, [])

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <AppContent>
                <Header onDrawerToggle={handleDrawerToggle} />
                <OrganizationHeader />
                <MainContent p={useMediaQuery(theme.breakpoints.up('lg')) ? 10 : 5}>
                    <React.Fragment>
                        {isProduction && showProductionWarningAlert && (
                            <GenericAlert type={MessageType.ERROR} iconType={MessageType.INFO}>
                                <Typography variant='body1' mb={4}>
                                    BE CAREFUL AND STAY ALERT! You are currently in production.
                                </Typography>
                                <Typography variant='body1'>
                                    When in doubt, seek guidance. It&apos;s easier to ask a question than it is to fix a mistake.
                                </Typography>
                            </GenericAlert>
                        )}
                        {!isUserProfileComplete && user && (
                            <Alert
                                severity='warning'
                                className='top'
                                action={
                                    <StyledLink to='/account/profile'>
                                        <LinkButton>
                                            <Typography variant='body2'>Go to Profile</Typography>
                                        </LinkButton>
                                    </StyledLink>
                                }
                            >
                                <Typography variant='body1'>
                                    Your profile is incomplete, please provide: <Strong>first name</Strong> and <Strong>last name</Strong>.
                                </Typography>
                            </Alert>
                        )}
                        {!hasDefaultBillingAddress && user && (
                            <Alert
                                severity='error'
                                className='top'
                                action={
                                    <StyledLink to='/addressbook' hidden={!isOrganizationAdmin}>
                                        <LinkButton>
                                            <Typography variant='body2'>Go to Address book</Typography>
                                        </LinkButton>
                                    </StyledLink>
                                }
                            >
                                {isOrganizationAdmin && (
                                    <Typography variant='body1'>
                                        Your address information is incomplete, please provide: <Strong>default billing address</Strong>
                                    </Typography>
                                )}
                                {!isOrganizationAdmin && (
                                    <Typography variant='body1'>
                                        Your organization doesn’t have a <Strong>default billing address</Strong>. Please ask your
                                        administrator to create one.
                                    </Typography>
                                )}
                            </Alert>
                        )}
                    </React.Fragment>
                    {children}

                    <Snackbar
                        open={snackbar.open}
                        anchorOrigin={snackbar.origin}
                        autoHideDuration={snackbar.autoHideDuration}
                        onClose={handleSnackbarClose}
                    >
                        <Alert severity={snackbar.severity} onClose={handleSnackbarClose}>
                            <Typography variant='body1'>{snackbar.message}</Typography>
                        </Alert>
                    </Snackbar>

                    <Snackbar open={versionSnackbarOpen} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity='info'>
                            <Grid container justifyContent='space-between'>
                                <Grid item xs='auto'>
                                    <VersionSnackbarTypography variant='body1'>
                                        A new version of Lazr is available
                                    </VersionSnackbarTypography>
                                </Grid>
                                <Grid item xs='auto'>
                                    <VersionSnackbarButton
                                        variant='outlined'
                                        onClick={() => {
                                            window.location.reload()
                                        }}
                                    >
                                        Reload
                                    </VersionSnackbarButton>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Snackbar>

                    {!monitor.sizeScreen.lg && !(window.matchMedia && window.matchMedia('print').matches === true) && (
                        <Snackbar
                            open={snackbarBestExperienceOpen}
                            onClose={(event, reason): any => handleSnackbarCookieBestExperienceClose(reason)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        >
                            <Alert severity='info'>
                                <Typography variant='body1'>
                                    For the best experience on our website, we recommend using a desktop computer. Some features and
                                    functionalities may be limited on small screen.
                                </Typography>
                                <Grid justifyContent='space-between' container spacing={6}>
                                    <Grid item />
                                    <Grid item>
                                        <CookieSnackbarButton
                                            color='primary'
                                            variant='text'
                                            size='medium'
                                            onClick={(): any => handleSnackbarCookieBestExperienceClose('hide')}
                                        >
                                            Hide This message
                                        </CookieSnackbarButton>
                                    </Grid>
                                </Grid>
                            </Alert>
                        </Snackbar>
                    )}
                    {
                    //  Remeve this comment to add message to the client \\ To do: future, lets create a system to add message
                    // messageSnakbarOpen && (
                    //     <Snackbar
                    //         open={messageSnakbarOpen}
                    //         onClose={(event, reason): any => {
                    //             setMessageSnakbarOpen(false)
                    //         }}
                    //         anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    //         style={{ marginRight: '20px' }}
                    //     >
                    //         <StyledAlertBanner severity='warning'>
                    //             <StyledAlertBannerContent
                    //                 title={t('Attention')}
                    //                 /* eslint-disable-next-line max-len */
                    //                 message={<StyledTypography variant='body1'>{t('message')}</StyledTypography>}
                    //                 buttons={
                    //                     <StyledButton
                    //                         size='medium'
                    //                         onClick={() => {
                    //                             setMessageSnakbarOpen(false)
                    //                             setCookie(cookieName, currentDate, 1)
                    //                         }}
                    //                     >
                    //                         OK
                    //                     </StyledButton>
                    //                 }
                    //             />
                    //         </StyledAlertBanner>
                    //     </Snackbar>
                    // )
                    
                    }
                    <Snackbar
                        open={snackbarCookieLawOpen}
                        onClose={(event, reason): any => handleSnackbarCookieLawClose(reason)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    >
                        <Alert severity='info'>
                            <Typography variant='body1'>
                                This site uses cookies to remember your preferences across different visits and otherwise provide you with a
                                great user experience.
                            </Typography>
                            <Typography variant='body1' mt={4}>
                                Cookies also help us understand how the application is being used for continuous improvements in ways that
                                matter to our users.
                            </Typography>
                            <Typography variant='body1' mt={4}>
                                By using Lazr, you accept our use of cookies.
                            </Typography>
                            <Grid justifyContent='space-between' container spacing={6}>
                                <Grid item />
                                <Grid item>
                                    <CookieSnackbarButton
                                        color='primary'
                                        variant='text'
                                        size='medium'
                                        onClick={(): any => handleSnackbarCookieLawClose('accept')}
                                    >
                                        Accept Cookies
                                    </CookieSnackbarButton>
                                </Grid>
                            </Grid>
                        </Alert>
                    </Snackbar>

                    {/* <NotistackAlerts messageUserSnackbar ={{open: messageSnakbarOpen, cookieName:cookieName, expiresDays: 1}} /> */}
                </MainContent>
                {ContentFooter && <ContentFooter />}
                <Footer />
            </AppContent>
        </Root>
    )
}

export interface Props extends LayoutProps {}

export default Dashboard

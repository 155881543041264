/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Locale } from '../models/Locale';
import type { SuccessResponse_GetAccessorialTypesResponse_Array_ } from '../models/SuccessResponse_GetAccessorialTypesResponse_Array_';
import type { SuccessResponse_GetB13aFilingOptionsResponse_Array_ } from '../models/SuccessResponse_GetB13aFilingOptionsResponse_Array_';
import type { SuccessResponse_GetBillDutiesToPartyTypesResponse_Array_ } from '../models/SuccessResponse_GetBillDutiesToPartyTypesResponse_Array_';
import type { SuccessResponse_GetBillingChargeTypesResponse_Array_ } from '../models/SuccessResponse_GetBillingChargeTypesResponse_Array_';
import type { SuccessResponse_GetCarriersResponse_Array_ } from '../models/SuccessResponse_GetCarriersResponse_Array_';
import type { SuccessResponse_GetCarrierTypesResponse_Array_ } from '../models/SuccessResponse_GetCarrierTypesResponse_Array_';
import type { SuccessResponse_GetCollectCarriersResponse_Array_ } from '../models/SuccessResponse_GetCollectCarriersResponse_Array_';
import type { SuccessResponse_GetCountriesResponse_Array_ } from '../models/SuccessResponse_GetCountriesResponse_Array_';
import type { SuccessResponse_GetCoverageCommodityTypesResponse_Array_ } from '../models/SuccessResponse_GetCoverageCommodityTypesResponse_Array_';
import type { SuccessResponse_GetCurrenciesResponse_Array_ } from '../models/SuccessResponse_GetCurrenciesResponse_Array_';
import type { SuccessResponse_GetCustomsBrokersResponse_Array_ } from '../models/SuccessResponse_GetCustomsBrokersResponse_Array_';
import type { SuccessResponse_GetDocumentTypesResponse_Array_ } from '../models/SuccessResponse_GetDocumentTypesResponse_Array_';
import type { SuccessResponse_GetEquipmentTypesResponse_Array_ } from '../models/SuccessResponse_GetEquipmentTypesResponse_Array_';
import type { SuccessResponse_GetEventTypesResponse_Array_ } from '../models/SuccessResponse_GetEventTypesResponse_Array_';
import type { SuccessResponse_GetExportComplianceStatementResponse_Array_ } from '../models/SuccessResponse_GetExportComplianceStatementResponse_Array_';
import type { SuccessResponse_GetGendersResponse_Array_ } from '../models/SuccessResponse_GetGendersResponse_Array_';
import type { SuccessResponse_GetHazmatClassTypesResponse_Array_ } from '../models/SuccessResponse_GetHazmatClassTypesResponse_Array_';
import type { SuccessResponse_GetHazmatModeTypesResponse_Array_ } from '../models/SuccessResponse_GetHazmatModeTypesResponse_Array_';
import type { SuccessResponse_GetImportExportTypesResponse_Array_ } from '../models/SuccessResponse_GetImportExportTypesResponse_Array_';
import type { SuccessResponse_GetIncotermsTypesResponse_Array_ } from '../models/SuccessResponse_GetIncotermsTypesResponse_Array_';
import type { SuccessResponse_GetLanguagesResponse_Array_ } from '../models/SuccessResponse_GetLanguagesResponse_Array_';
import type { SuccessResponse_GetLocalesResponse_Array_ } from '../models/SuccessResponse_GetLocalesResponse_Array_';
import type { SuccessResponse_GetNonDeliveryHandlingTypesResponse_Array_ } from '../models/SuccessResponse_GetNonDeliveryHandlingTypesResponse_Array_';
import type { SuccessResponse_GetOrderStatusesResponse_Array_ } from '../models/SuccessResponse_GetOrderStatusesResponse_Array_';
import type { SuccessResponse_GetOrganizationTypesResponse_Array_ } from '../models/SuccessResponse_GetOrganizationTypesResponse_Array_';
import type { SuccessResponse_GetPackageTypesResponse_Array_ } from '../models/SuccessResponse_GetPackageTypesResponse_Array_';
import type { SuccessResponse_GetReasonForExportTypesResponse_Array_ } from '../models/SuccessResponse_GetReasonForExportTypesResponse_Array_';
import type { SuccessResponse_GetShipmentStatusesResponse_Array_ } from '../models/SuccessResponse_GetShipmentStatusesResponse_Array_';
import type { SuccessResponse_GetTimezonesResponse_Array_ } from '../models/SuccessResponse_GetTimezonesResponse_Array_';
import type { SuccessResponse_GetTransportTypesResponse_Array_ } from '../models/SuccessResponse_GetTransportTypesResponse_Array_';
import type { SuccessResponse_GetUserTitlesResponse_Array_ } from '../models/SuccessResponse_GetUserTitlesResponse_Array_';
import type { TransportType } from '../models/TransportType';
import { request as __request } from '../core/request';

export class EnumTypesService {

    /**
     * Get a list of the types for the accessorials
     * @returns SuccessResponse_GetAccessorialTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getAccessorialTypes(): Promise<SuccessResponse_GetAccessorialTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/accessorial-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of b13a filing options
     * @returns SuccessResponse_GetB13aFilingOptionsResponse_Array_ OK
     * @throws ApiError
     */
    public static async getB13AFilingOptions(): Promise<SuccessResponse_GetB13aFilingOptionsResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/b13a-filing-option`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of bill duties to party
     * @returns SuccessResponse_GetBillDutiesToPartyTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getBillDutiesToPartyTypes(): Promise<SuccessResponse_GetBillDutiesToPartyTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/bill-duties-to-party-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of the billing charge types
     * @param transportTypes The transportTypes filter
     * @returns SuccessResponse_GetBillingChargeTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getBillingChargeTypes(
transportTypes?: Array<TransportType>,
): Promise<SuccessResponse_GetBillingChargeTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/billing-charge-types`,
            query: {
                'transportTypes': transportTypes,
            },
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of carriers
     * @param processInvoiceSupport 
     * @param processTrackingUpdateSupport 
     * @param supportPickup 
     * @param supportHoldForPickup 
     * @returns SuccessResponse_GetCarriersResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCarriers(
processInvoiceSupport?: boolean,
processTrackingUpdateSupport?: boolean,
supportPickup?: boolean,
supportHoldForPickup?: boolean,
): Promise<SuccessResponse_GetCarriersResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/carriers`,
            query: {
                'processInvoiceSupport': processInvoiceSupport,
                'processTrackingUpdateSupport': processTrackingUpdateSupport,
                'supportPickup': supportPickup,
                'supportHoldForPickup': supportHoldForPickup,
            },
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of types for the carriers
     * @returns SuccessResponse_GetCarrierTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCarrierTypes(): Promise<SuccessResponse_GetCarrierTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/carrier-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of collect carriers | filterable by an array of transport types
     * @param transportTypes 
     * @returns SuccessResponse_GetCollectCarriersResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCollectCarriers(
transportTypes?: Array<TransportType>,
): Promise<SuccessResponse_GetCollectCarriersResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/collect-carriers`,
            query: {
                'transportTypes': transportTypes,
            },
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of countries
     * @returns SuccessResponse_GetCountriesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCountries(): Promise<SuccessResponse_GetCountriesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/countries`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of coverage commodity types
     * @returns SuccessResponse_GetCoverageCommodityTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCoverageCommodityTypes(): Promise<SuccessResponse_GetCoverageCommodityTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/coverage-commodity-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of currencies
     * @returns SuccessResponse_GetCurrenciesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCurrencies(): Promise<SuccessResponse_GetCurrenciesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/currencies`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of the customs brokers
     * @returns SuccessResponse_GetCustomsBrokersResponse_Array_ OK
     * @throws ApiError
     */
    public static async getCustomsBrokers(): Promise<SuccessResponse_GetCustomsBrokersResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/customs-brokers`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of document types
     * @returns SuccessResponse_GetDocumentTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getDocumentTypes(): Promise<SuccessResponse_GetDocumentTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/document-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of equipment types
     * @returns SuccessResponse_GetEquipmentTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getEquipmentTypes(): Promise<SuccessResponse_GetEquipmentTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/equipment-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of types of events
     * @returns SuccessResponse_GetEventTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getEventTypes(): Promise<SuccessResponse_GetEventTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/event-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of export compliance statement
     * @returns SuccessResponse_GetExportComplianceStatementResponse_Array_ OK
     * @throws ApiError
     */
    public static async getExportComplianceStatement(): Promise<SuccessResponse_GetExportComplianceStatementResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/export-compliance-statement`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of genders
     * @returns SuccessResponse_GetGendersResponse_Array_ OK
     * @throws ApiError
     */
    public static async getGenders(): Promise<SuccessResponse_GetGendersResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/genders`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of hazmat class types
     * @returns SuccessResponse_GetHazmatClassTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getHazmatClassTypes(): Promise<SuccessResponse_GetHazmatClassTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/hazmat-class-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of hazmat mode types
     * @returns SuccessResponse_GetHazmatModeTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getHazmatModeTypes(): Promise<SuccessResponse_GetHazmatModeTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/hazmat-mode-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of import export types
     * @returns SuccessResponse_GetImportExportTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getImportExportTypes(): Promise<SuccessResponse_GetImportExportTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/import-export-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of incoterms types
     * @returns SuccessResponse_GetIncotermsTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getIncotermsTypes(): Promise<SuccessResponse_GetIncotermsTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/incoterms-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of languages
     * @returns SuccessResponse_GetLanguagesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getLanguages(): Promise<SuccessResponse_GetLanguagesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/languages`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of locales
     * @param code The locale code
     * @returns SuccessResponse_GetLocalesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getLocales(
code?: Locale,
): Promise<SuccessResponse_GetLocalesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/locales`,
            query: {
                'code': code,
            },
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of non delivery handling types
     * @returns SuccessResponse_GetNonDeliveryHandlingTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getNonDeliveryHandlingTypes(): Promise<SuccessResponse_GetNonDeliveryHandlingTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/non_delivery_handling_type`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of order statuses
     * @returns SuccessResponse_GetOrderStatusesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getOrderStatuses(): Promise<SuccessResponse_GetOrderStatusesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/order-statuses`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of types of organizations
     * @returns SuccessResponse_GetOrganizationTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getOrganizationTypes(): Promise<SuccessResponse_GetOrganizationTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/organization-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of package types
     * @returns SuccessResponse_GetPackageTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getPackageTypes(): Promise<SuccessResponse_GetPackageTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/package-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of reason for export types
     * @returns SuccessResponse_GetReasonForExportTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getReasonForExportTypes(): Promise<SuccessResponse_GetReasonForExportTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/reason-for-export-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of shipment statuses
     * @returns SuccessResponse_GetShipmentStatusesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getShipmentStatuses(): Promise<SuccessResponse_GetShipmentStatusesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/shipment-statuses`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of timezones
     * @returns SuccessResponse_GetTimezonesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getTimezones(): Promise<SuccessResponse_GetTimezonesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/timezones`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of transport types
     * @returns SuccessResponse_GetTransportTypesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getTransportTypes(): Promise<SuccessResponse_GetTransportTypesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/transport-types`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

    /**
     * Get a list of user titles
     * @returns SuccessResponse_GetUserTitlesResponse_Array_ OK
     * @throws ApiError
     */
    public static async getUserTitles(): Promise<SuccessResponse_GetUserTitlesResponse_Array_> {
        const result = await __request({
            method: 'GET',
            path: `/user-titles`,
            errors: {
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}